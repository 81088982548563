const baseColors = {
  blue: [
    "#E8EAF6", // 0
    "#C5CAE9", // 1
    "#9FA8DA", // 2
    "#7986CB", // 3
    "#5C6BC0", // 4
    "#3F51B5", // 5
    "#3949AB", // 6
    "#303F9F", // 7
    "#283593", // 8
    "#1A237E", // 9
  ],
  green: [
    "#EAFAF1", // 0
    "#D5F5E3", // 1
    "#ABEBC6", // 2
    "#82E0AA", // 3
    "#58D68D", // 4
    "#2ECC71", // 5
    "#28B463", // 6
    "#239B56", // 7
    "#1D8348", // 8
    "#186A3B", // 9
  ],
  grey: [
    "#EBEDEF", // 0
    "#D6DBDF", // 1
    "#AEB6BF", // 2
    "#85929E", // 3
    "#5D6D7E", // 4
    "#34495E", // 5
    "#2E4053", // 6
    "#283747", // 7
    "#212F3C", // 8
    "#1B2631", // 9
  ],
  orange: [
    "#FEF5E7", // 0
    "#FDEBD0", // 1
    "#FAD7A0", // 2
    "#F8C471", // 3
    "#F5B041", // 4
    "#F39C12", // 5
    "#D68910", // 6
    "#B9770E", // 7
    "#9C640C", // 8
    "#7E5109", // 9
  ],
  red: [
    "#F9EBEA", // 0
    "#F2D7D5", // 1
    "#E6B0AA", // 2
    "#D98880", // 3
    "#CD6155", // 4
    "#C0392B", // 5
    "#A93226", // 6
    "#922B21", // 7
    "#7B241C", // 8
    "#641E16", // 9
  ],
  turquoise: [
    "#E8F6F3", // 0
    "#D0ECE7", // 1
    "#A2D9CE", // 2
    "#73C6B6", // 3
    "#45B39D", // 4
    "#16A085", // 5
    "#138D75", // 6
    "#117A65", // 7
    "#0E6655", // 8
    "#0B5345", // 9
  ],
};

export default baseColors;
