const menuContent = [
  {
    text: 'Projects',
    route: '/',
  },
  {
    text: 'About me',
    route: '/about',
  },
];

export default menuContent;